/* src/input.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body, .App-header {
  font-size: 1rem; /* Sets the base font size to 16px */
}

body {
  background-color: #001f3f; /* Dark blue */
  margin: 0;
  padding: 0;
}

.tabAnim {
  z-index: 5;
}

.secondary-color {
  background-color: #37c6c1;
}

#toggleEnable:checked + label + #toggleDisable + label ~ .tabAnim {
  --tw-translate-x: 0%;
}

#toggleDisable:checked ~ .tabAnim {
  --tw-translate-x: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

img.App-logo {
  max-height: 3rem !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: cyan;
}

a:visited {
  color: rgb(255, 255, 255);
}

a.black-text-button {
  color: black;
}


.nft-card-image {
  border-radius: 0.75rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.nft-display::-webkit-scrollbar {
  display: none;
}

.bg-page-background {
  background-image: url('/public/assets/shipTwo.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-page-background-two {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 769px) {
  .bg-page-background-two {
    background-image:  url('/public/assets/faceOff.png');
  }
}

@media (max-width: 768px) {
  .bg-page-background-two {
    background-image: url('/public/assets/faceOff.png');
  }
}

.bg-page-background-full {
  background-image: url('/public/assets/featuredNFTCard.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Ensure it covers the full height of the viewport */
}

.custom-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 20px;
  color: #000;
  background: linear-gradient(to bottom, #a4d47d, #7ec855); /* Gradient background */
  border: 2px solid #000;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), /* Soft shadow */
              0 8px 10px rgba(0, 0, 0, 0.2); /* Bottom shadow */
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s; /* Smooth animation */
}
